/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect, useSelector } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { navigateTab } from '../../../reducers/screen';

import IssueFilterMobile from '../../issues/IssueFilter/IssueFilter_MOBILE';

import DesktopLogo from '../../../assets/icon/header-logo.svg';
import MobileLogo from '../../../assets/icon/header-mobile-logo.svg';

import OverviewIcon from '../../../assets/icon/dashboard-overview.svg';
import TrendsIcon from '../../../assets/icon/dashboard-trends.svg';
import SitesIcon from '../../../assets/icon/dashboard-sites.svg';
import IssuesIcon from '../../../assets/icon/dashboard-issues.svg';
import AuditorsIcon from '../../../assets/icon/dashboard-auditors.svg';
import InventoryIcon from '../../../assets/icon/dashboard-inventory.svg';
import salesCalculationIcon from '../../../assets/icon/dashboard-sales-calculator.svg';
import { MdOutlineInsertChart } from 'react-icons/md';

import ManageIcon from '../../../assets/icon/admin-manage.svg';
import DepartmentIcon from '../../../assets/icon/admin-department.svg';
import QuestionnairesIcon from '../../../assets/icon/admin-questionnaires.svg';
import UsersAdminIcon from '../../../assets/icon/admin-auditors.svg';
import SitesAdminIcon from '../../../assets/icon/admin-sites.svg';
import SalesTargetIcon from '../../../assets/icon/admin-sales.svg';
import PromotionsIcon from '../../../assets/icon/admin-promotions.svg';
import BroadcastIcon from '../../../assets/icon/admin-broadcast.svg';
import InventoryAdminIcon from '../../../assets/icon/admin-inventory.svg';
import POIcon from '../../../assets/icon/admin-po.svg';
import OffDaysIcon from '../../../assets/icon/admin-offdays.svg';
import DownloadManagerIcon from '../../../assets/icon/admin-download-manager.svg';
import LogoutIcon from '../../../assets/icon/download-icon.svg';
import HelpIcon from '../../../assets/icon/tooltip-help.svg';
import { IoImagesOutline } from 'react-icons/io5';
import { FaRegFolderOpen } from 'react-icons/fa';

import CoursesIcon from '../../../assets/icon/sidebar/nimblylearn-courses.svg';
import CoursesManagementIcon from '../../../assets/icon/sidebar/nimblylearn-courses-management.svg';
import NimblyLearnDashboardIcon from '../../../assets/icon/sidebar/nimblylearn-dashboard.svg';
import UserGroupsIcon from '../../../assets/icon/sidebar/nimblylearn-user-groups.svg';
import UserManagementIcon from '../../../assets/icon/sidebar/nimblylearn-user-management.svg';
import UsersIcon from '../../../assets/icon/sidebar/nimblylearn-users.svg';
import QuizIcon from '../../../assets/icon/sidebar/nimblylearn-quiz.svg';

import SettingsIcon from '../../../assets/icon/settings-general.svg';
import RoleManagerIcon from '../../../assets/icon/settings-role_manager.svg';
import AccessControlIcon from '../../../assets/icon/settings-access_control.svg';
import FeatureAccessIcon from '../../../assets/icon/settings-feature-access.svg';

import i18n from '../../../i18n';

import DropdownIcon from '../../../assets/icon/chevron-dark.svg';

import { Translation, useTranslation } from 'react-i18next';
import * as Common from 'nimbly-common';

import { createNewActivity } from 'constants/api/v1';
import MobileTabs from './components/MobileTabs';

import Executive_INACTIVE from 'assets/icon/sidebar/executive-dashboard-icon-inactive.svg';
import Executive_ACTIVE from 'assets/icon/sidebar/executive-dashboard-mobile-active.svg';
import { SuspenseInitialRender } from 'core/utility/react/suspenseRender';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useMediaQuery } from 'core/utility/react/mediaQuery';

// eslint-disable-next-line complexity
const AdminSidebar = (props) => {
	const handleTabCollapse = () => props.onCollapse();
	const handleTabSelect = async (val, type) => {
		// ROLE - SIDEBAR - ADMINVALIDATION
		props.onSelect(val, type);
	};
	const { t } = useTranslation();

	const { isReady, selected, isCollapsed, adminAccess, featureAccess, lmsRole } = props;

	const manageSubTabs = [
		'departmentsgroup',
		'departments',
		'auditors',
		'sites',
		'questionnaires',
		'skuManagement',
		'stockAuditScheduler',
		'purchaseorder',
		'calculation',
		'downloadManager',
		'competitorAnalysis',
	];

	const manageTabActive = manageSubTabs.includes(selected);

	return (
		<Container>
			<Tabs>
				<CollapsibleTab isActive={manageTabActive} isCollapsed={isCollapsed} onClick={handleTabCollapse}>
					<img src={ManageIcon} alt="departments-icon" />
					<span>{t('nav.manage')}</span>
					<span className="dropdown">
						<img src={DropdownIcon} alt="dropdown" />
					</span>
				</CollapsibleTab>
				<GroupedTabs isCollapsed={isCollapsed}>
					<SubTab
						isActive={isReady && selected === 'departmentsgroup'}
						onClick={() => handleTabSelect('departmentsgroup', Common.enums.RoleResources.ADMIN_DEPARTMENT_ALL)}
					>
						<img src={DepartmentIcon} alt="departments-icon" />
						<span>{t('nav.departmentsgroup')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'departments'}
						onClick={() => handleTabSelect('departments', Common.enums.RoleResources.ADMIN_DEPARTMENT_ALL)}
					>
						<img src={DepartmentIcon} alt="departments-icon" />
						<span>{t('nav.departments')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'questionnaires'}
						onClick={() => handleTabSelect('questionnaires', Common.enums.RoleResources.ADMIN_QUESTIONNAIRE_ALL)}
					>
						<img src={QuestionnairesIcon} alt="questionnaires-icon" />
						<span>{t('nav.questionnaires')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'auditors'}
						onClick={() => handleTabSelect('auditors', Common.enums.RoleResources.ADMIN_USER_ALL)}
					>
						<img src={UsersAdminIcon} alt="auditors-icon" />
						<span>{t('nav.users')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'sites'}
						onClick={() => handleTabSelect('sites', Common.enums.RoleResources.ADMIN_SITES_ALL)}
					>
						<img src={SitesAdminIcon} alt="sites-icon" />
						<span>{t('nav.sites')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'journeyPlan'}
						onClick={() => handleTabSelect('journeyPlan', Common.enums.RoleResources.ADMIN_QUESTIONNAIRE_ALL)} // ==> change
					>
						<img src={SitesAdminIcon} alt="questionnaires-icon" />
						<span>{t('nav.journeyPlan')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'competitorAnalysis'}
						onClick={() => handleTabSelect('competitorAnalysis', Common.enums.RoleResources.ADMIN_SITES_ALL)}
					>
						<img src={SitesAdminIcon} alt="sites-icon" />
						<span>{t('nav.competitorAnalysis')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'skuManagement'}
						onClick={() => handleTabSelect('skuManagement', Common.enums.RoleResources.ADMIN_INVENTORY_ALL)}
					>
						<img src={InventoryAdminIcon} alt="inventory-icon" />
						<span>{t('nav.skuManagement')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'stockAuditScheduler'}
						onClick={() => handleTabSelect('stockAuditScheduler', Common.enums.RoleResources.ADMIN_INVENTORY_ALL)}
					>
						<img src={InventoryAdminIcon} alt="stock-audit-scheduler-icon" />
						<span>{t('nav.stockAuditScheduler')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'purchaseorder'}
						onClick={() => handleTabSelect('purchaseorder', Common.enums.RoleResources.ADMIN_PURCHASEORDER_ALL)}
					>
						<img src={POIcon} alt="purchaseorder-icon" />
						<span>{t('nav.po')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'offdays'}
						onClick={() => handleTabSelect('offdays', Common.enums.RoleResources.ADMIN_NONOPERATIONALDAYS_ALL)}
					>
						<img src={OffDaysIcon} alt="offdays-icon" />
						<span>{t('nav.offDays')}</span>
					</SubTab>
					<SubTab
						isActive={isReady && selected === 'downloadManager'}
						onClick={() => handleTabSelect('downloadManager', Common.enums.RoleResources.ADMIN_DOWNLOADMANAGER_ALL)}
					>
						<img src={DownloadManagerIcon} alt="download-manager-icon" />
						<span>{t('nav.downloadManager')}</span>
					</SubTab>
				</GroupedTabs>
				<Tab
					isActive={isReady && selected === 'salestarget'}
					onClick={() => handleTabSelect('salestarget', Common.enums.RoleResources.ADMIN_SALESTARGET_ALL)}
				>
					<img src={SalesTargetIcon} alt="inventory-icon" />
					<span>{t('nav.salesTarget')}</span>
				</Tab>

				<Tab
					isActive={isReady && selected === 'promotions'}
					onClick={() => handleTabSelect('promotions', Common.enums.RoleResources.ADMIN_PROMOTION_ALL)}
				>
					<img src={PromotionsIcon} alt="promotions-icon" />
					<span>{t('nav.promotions')}</span>
				</Tab>
				<Tab
					isActive={isReady && selected === 'broadcast'}
					onClick={() => handleTabSelect('broadcast', Common.enums.RoleResources.ADMIN_BROADCAST_ALL)}
				>
					<img src={BroadcastIcon} alt="auditors-icon" />
					<span>{t('nav.broadcast')}</span>
				</Tab>
			</Tabs>
			<Tabs>
				<Tab isActive={isReady && selected === 'help'} onClick={() => handleTabSelect('help', {})}>
					<img src={HelpIcon} alt="helps-icon" />
					<span>Help</span>
				</Tab>
			</Tabs>
		</Container>
	);
};

class Sidebar extends Component {
	state = {
		isCollapsed: false,
		isReady: false,
		isActive: false,
		lang: 'id',
	};

	handleLanguageChange = (lang) => {
		const { auth } = this.props;
		const userRef = this.props.firebase.database().ref(`user/${auth.uid}`);
		this.props.firebase.update(userRef, {
			language: lang,
		});
		i18n.changeLanguage(lang);

		ReactGA.event({
			category: 'Navigation',
			action: 'Language - Changed to: ' + lang,
		});
	};

	componentDidMount = () => {
		const {
			location: { pathname },
			dispatch,
		} = this.props;
		const path = pathname[0] === '/' ? pathname.substring(1).split('/') : pathname.split('/');
		const base = path[0];
		const option = path[1];
		if (base === 'admin') {
			dispatch(navigateTab('admin', option));
		}
		if (base === 'analytics') {
			if (option === 'auditorDetails') {
				dispatch(navigateTab('auditorDetails', option));
			} else {
				dispatch(navigateTab('dashboard', option));
			}
		}
		this.setState({ isReady: true });
	};

	componentDidUpdate(prevProps) {
		// updates redux if user presses back
		if (
			this.props.history.action === 'POP' &&
			this.props.location.key !== prevProps.location.key &&
			this.props.location.pathname.indexOf('admin') > -1 &&
			this.props.location.pathname !== `/admin/${this.props.screen.admin.viewTab}`
		) {
			const option = this.props.location.pathname.split('/');
			this.props.dispatch(navigateTab('admin', option[option.length - 1]));
		}
	}

	handleCloseSidebar = () => {
		this.props.onDismiss();
	};

	handleTabCollapse = () => {
		this.setState({ isCollapsed: !this.state.isCollapsed });
	};

	handleLogout = () => {
		const { auth } = this.props;
		localStorage.removeItem('enrollmentStatus');

		// Create User Logout Activity
		const activity = new Common.Activity({
			entity: Common.enums.Entity.USER,
			entityID: auth.uid,
			permission: Common.enums.Permission.View,
			type: Common.enums.ActivityType.LOGOUT_USER,
			message: `User Logout: ${auth.displayName}`,
			timestamp: new Date(),
			snapshot: {
				userData: {
					email: auth.email,
					userID: auth.uid,
					displayName: auth.displayName,
				},
				lastSignInTime: auth.lastLoginAt,
				platform: 'admin',
			},
		});

		// Push to placeholder loading page while we log out
		this.props.dispatch(push('/loading'));
		createNewActivity(activity).then(() => {
			this.props.firebase.logout().then(() => {
				// Reload the page to empty the redux state and start from 0
				window.location.reload();
			});
		});
	};

	handleChangeSelection = async (option, type) => {
		const { location } = this.props;
		// ROLE - SIDEBAR - DASHBOARDVALIDATION
		if (option === 'help') {
			this.props.history.push({ pathname: `/help` });
		} else if (option === 'gallery') {
			this.props.history.push({ pathname: `/gallery` });
		} else if (option === 'repository') {
			this.props.history.push({ pathname: `/repository` });
		} else {
			if (location.pathname.indexOf('superadmin') > -1) {
				this.props.dispatch(navigateTab('superadmin', option));
				this.props.history.push({ pathname: `/superadmin/${option}` });
			} else if (location.pathname.indexOf('admin') > -1) {
				this.props.dispatch(navigateTab('admin', option));
				this.props.history.push({ pathname: `/admin/${option}` });
			} else if (location.pathname.indexOf('nimblylearn') > -1) {
				this.props.dispatch(navigateTab('nimblylearn', option));
				this.props.history.push({ pathname: `/nimblylearn/${option}` });
			} else if (location.pathname.indexOf('settings') > -1) {
				this.props.dispatch(navigateTab('settings', option));
				this.props.history.push({ pathname: `/settings/${option}` });
			} else {
				this.props.dispatch(navigateTab('dashboard', option));
				this.props.history.push({ pathname: `/analytics/${option}` });
				this.props.onDismiss();
			}
		}
	};

	_renderDashboardSidebar = (tab) => {
		const { isReady } = this.state;

		return (
			<Translation>
				{(t) => (
					<Container>
						<Tabs>
							<Tab
								isActive={isReady && tab === 'overview'}
								onClick={() =>
									this.handleChangeSelection('overview', Common.enums.RoleResources.DASHBOARD_OVERVIEW_ALL)
								}
							>
								<img src={OverviewIcon} alt="overview-icon" />
								<span>{t('nav.overview')}</span>
							</Tab>
							<Tab
								isActive={isReady && tab === 'trends'}
								onClick={() => this.handleChangeSelection('trends', Common.enums.RoleResources.DASHBOARD_TRENDS_ALL)}
							>
								<img src={TrendsIcon} alt="trends-icon" />
								<span>{t('nav.trends')}</span>
							</Tab>
							<Tab
								isActive={isReady && tab === 'sites'}
								onClick={() => this.handleChangeSelection('sites', Common.enums.RoleResources.DASHBOARD_SITES_ALL)}
							>
								<img src={SitesIcon} alt="sites-icon" />
								<span>{t('nav.sites')}</span>
							</Tab>
							<Tab
								isActive={isReady && tab === 'issues'}
								onClick={() => this.handleChangeSelection('issues', Common.enums.RoleResources.DASHBOARD_ISSUES_ALL)}
							>
								<img src={IssuesIcon} alt="issues-icon" />
								<span>{t('nav.issues')}</span>
							</Tab>
							<Tab
								isActive={isReady && tab === 'auditors'}
								onClick={() => this.handleChangeSelection('auditors', Common.enums.RoleResources.DASHBOARD_USERS_ALL)}
							>
								<img src={AuditorsIcon} alt="auditors-icon" />
								<span>{t('nav.users')}</span>
							</Tab>
							<Tab
								isActive={isReady && tab === 'calculation'}
								onClick={() =>
									this.handleChangeSelection('calculation', Common.enums.RoleResources.DASHBOARD_SALESCALCULATIONS_ALL)
								}
							>
								<img src={salesCalculationIcon} alt="stocks-icon" />
								<span>{t('nav.salesCalculation')}</span>
							</Tab>
							<Tab
								isActive={isReady && tab === 'ceo-persona'}
								onClick={() =>
									this.handleChangeSelection('ceo-persona', Common.enums.RoleResources.DASHBOARD_OVERVIEW_ALL)
								}
							>
								<img
									src={isReady && tab === 'ceo-persona' ? Executive_ACTIVE : Executive_INACTIVE}
									alt="ceo-persona-icon"
								/>
								<span>{t('nav.ceopersona')}</span>
							</Tab>
						</Tabs>
						<Tabs>
							<Tab isActive={isReady && tab === 'help'} onClick={() => this.handleChangeSelection('help', {})}>
								<img src={HelpIcon} alt="help-icon" />
								<span>Help</span>
							</Tab>
						</Tabs>
					</Container>
				)}
			</Translation>
		);
	};

	_renderSettingSidebar = (tab) => {
		return (
			<Translation>
				{(t) => (
					<Container>
						<Tabs>
							<Tab
								isActive={tab === 'general'}
								onClick={() =>
									this.handleChangeSelection('general', Common.enums.RoleResources.SETTING_PERMISSION_GENERAL_ALL)
								}
							>
								<img src={SettingsIcon} alt="overview-icon" />
								<span>{t('nav.general')}</span>
							</Tab>
							<Tab isActive={tab === 'role-manager'} onClick={() => this.handleChangeSelection('role-manager')}>
								<img src={RoleManagerIcon} alt="overview-icon" />
								<span>{t('nav.roleManager')}</span>
							</Tab>
							<Tab
								isActive={tab === 'permissions'}
								onClick={() =>
									this.handleChangeSelection('permissions', Common.enums.RoleResources.SETTING_PERMISSION_ACCESS_ALL)
								}
							>
								<img src={AccessControlIcon} alt="overview-icon" />
								<span>{t('nav.accessControl')}</span>
							</Tab>
							<Tab isActive={tab === 'feature-access'} onClick={() => this.handleChangeSelection('feature-access')}>
								<FeatureIcon src={FeatureAccessIcon} alt="overview-icon" />
								<span>{t('nav.featureAccess')}</span>
							</Tab>
							<Tab
								isActive={tab === 'integration'}
								onClick={() =>
									this.handleChangeSelection(
										'integration',
										Common.enums.RoleResources.SETTING_PERMISSION_INTEGRATION_ALL,
									)
								}
							>
								<img src={AccessControlIcon} alt="overview-icon" />
								<span>{t('nav.apiIntegration')}</span>
							</Tab>
						</Tabs>
					</Container>
				)}
			</Translation>
		);
	};

	_renderAdminSidebar = (tab) => {
		const { isReady, isCollapsed } = this.state;
		// TODO: CollapsibleTab gets reopened on click tab outside its selection due to re-render (this.state gets resetted)
		return (
			<AdminSidebar
				selected={tab}
				isReady={isReady}
				isCollapsed={isCollapsed}
				onSelect={this.handleChangeSelection}
				onCollapse={this.handleTabCollapse}
				dispatch={this.props.dispatch}
			/>
		);
	};

	_renderSuperadminSidebar = (tab) => {
		const { isReady } = this.state;
		return (
			<Tabs>
				<Tab isActive={isReady && tab === ''} onClick={() => this.handleChangeSelection('')}>
					<img src={OverviewIcon} alt="departments-icon" />
					<span>Organization</span>
				</Tab>
				<Tab isActive={isReady && tab === 'npsSurvey'} onClick={() => this.handleChangeSelection('npsSurvey')}>
					<img src={QuestionnairesIcon} alt="nps-icon" />
					<span>NPS Survey</span>
				</Tab>
				<Tab isActive={isReady && tab === 'errorlog'} onClick={() => this.handleChangeSelection('errorlog')}>
					<img src={IssuesIcon} alt="error-icon" />
					<span>Error Log</span>
				</Tab>
				<Tab isActive={isReady && tab === 'clientLogin'} onClick={() => this.handleChangeSelection('clientLogin')}>
					<img src={AccessControlIcon} alt="clientLogin-icon" />
					<span>Login as Client</span>
				</Tab>
			</Tabs>
		);
	};

	_renderEmptySidebar = () => {
		return (
			<Tabs>
				<Tab />
			</Tabs>
		);
	};

	_renderMobileSettings = (tab) => {
		return (
			<Translation>
				{(t) => (
					<Tabs>
						<MobileTab isActive={tab === 'general'} onClick={() => this.handleChangeSelection('general')}>
							<img src={OverviewIcon} alt="departments-icon" />
							<span>{t('nav.general')}</span>
						</MobileTab>
						<MobileTab isActive={tab === 'role-manager'} onClick={() => this.handleChangeSelection('role-manager')}>
							<img src={OverviewIcon} alt="questionnaires-icon" />
							<span>{t('nav.roleManager')}</span>
						</MobileTab>
						<MobileTab isActive={tab === 'permissions'} onClick={() => this.handleChangeSelection('permissions')}>
							<img src={OverviewIcon} alt="questionnaires-icon" />
							<span>{t('nav.accessControl')}</span>
						</MobileTab>
						<MobileTab isActive={tab === 'feature-access'} onClick={() => this.handleChangeSelection('feature-access')}>
							<img src={OverviewIcon} alt="questionnaires-icon" />
							<span>{t('nav.featureAccess')}</span>
						</MobileTab>
						<MobileTab isActive={tab === 'integration'} onClick={() => this.handleChangeSelection('integration')}>
							<img src={OverviewIcon} alt="questionnaires-icon" />
							<span>{t('nav.apiIntegration')}</span>
						</MobileTab>
					</Tabs>
				)}
			</Translation>
		);
	};

	_renderMobileAdminSidebar = (tab) => {
		const { isReady } = this.state;

		return (
			<Translation>
				{(t) => (
					<Tabs>
						<MobileTab
							isActive={isReady && tab === 'departments'}
							onClick={() => this.handleChangeSelection('departments', Common.enums.RoleResources.ADMIN_DEPARTMENT_ALL)}
						>
							<img src={DepartmentIcon} alt="departments-icon" />
							<span>{t('nav.departments')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'questionnaires'}
							onClick={() =>
								this.handleChangeSelection('questionnaires', Common.enums.RoleResources.ADMIN_QUESTIONNAIRE_ALL)
							}
						>
							<img src={QuestionnairesIcon} alt="questionnaires-icon" />
							<span>{t('nav.questionnaires')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'auditors'}
							onClick={() => this.handleChangeSelection('auditors', Common.enums.RoleResources.ADMIN_USER_ALL)}
						>
							<img src={UsersAdminIcon} alt="auditors-icon" />
							<span>{t('nav.users')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'sites'}
							onClick={() => this.handleChangeSelection('sites', Common.enums.RoleResources.ADMIN_SITES_ALL)}
						>
							<img src={SitesAdminIcon} alt="sites-icon" />
							<span>{t('nav.sites')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'journeyPlan'}
							onClick={() =>
								this.handleChangeSelection('journeyPlan', Common.enums.RoleResources.ADMIN_QUESTIONNAIRE_ALL)
							}
						>
							<img src={SitesAdminIcon} alt="questionnaires-icon" />
							<span>{t('nav.journeyPlan')}</span>
						</MobileTab>

						<MobileTab
							isActive={isReady && tab === 'salestarget'}
							onClick={() =>
								this.handleChangeSelection('salestarget', Common.enums.RoleResources.ADMIN_SALESTARGET_ALL)
							}
						>
							<img src={SalesTargetIcon} alt="inventory-icon" />
							<span>{t('nav.salesTarget')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'promotions'}
							onClick={() => this.handleChangeSelection('promotions', Common.enums.RoleResources.ADMIN_PROMOTION_ALL)}
						>
							<img src={PromotionsIcon} alt="promotions-icon" />
							<span>{t('nav.promotions')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'broadcast'}
							onClick={() => this.handleChangeSelection('broadcast', Common.enums.RoleResources.ADMIN_BROADCAST_ALL)}
						>
							<img src={BroadcastIcon} alt="auditors-icon" />
							<span>{t('nav.broadcast')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'skuManagement'}
							onClick={() =>
								this.handleChangeSelection('skuManagement', Common.enums.RoleResources.ADMIN_INVENTORY_ALL)
							}
						>
							<img src={InventoryAdminIcon} alt="inventory-icon" />
							<span>{t('nav.skuManagement')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'stockAuditScheduler'}
							onClick={
								() => this.handleChangeSelection('stockAuditScheduler', Common.enums.RoleResources.ADMIN_INVENTORY_ALL) // TODO: [NIMT-2409] Create Stock Audit Scheduler feature access
							}
						>
							<img src={InventoryAdminIcon} alt="stock-audit-scheduler-icon" />
							<span>{t('nav.stockAuditScheduler')}</span>
						</MobileTab>

						<MobileTab
							isActive={isReady && tab === 'offdays'}
							onClick={() =>
								this.handleChangeSelection('offdays', Common.enums.RoleResources.ADMIN_NONOPERATIONALDAYS_ALL)
							}
						>
							<img src={OffDaysIcon} alt="purchaseorder-icon" />
							<span>{t('nav.offDays')}</span>
						</MobileTab>

						<MobileTab
							isActive={isReady && tab === 'customerFeedback'}
							onClick={() =>
								this.handleChangeSelection('customerFeedback', Common.enums.RoleResources.ADMIN_QUESTIONNAIRE_ALL)
							}
						>
							<img src={SitesIcon} alt="customerFeedback-icon" />
							<span>{t('nav.customerFeedback')}</span>
						</MobileTab>
						<MobileTab isActive={isReady && tab === 'help'} onClick={() => this.handleChangeSelection('help', {})}>
							<img src={HelpIcon} alt="help-icon" style={{ padding: '0px 5px' }} width="25px" />
							<span>Help</span>
						</MobileTab>
					</Tabs>
				)}
			</Translation>
		);
	};

	_renderMobileNimblyLearnSidebar = (tab) => {
		const { lmsRoleAccess, adminAccess, lmsRole } = this.props;

		return (
			<Translation>
				{(t) => (
					<MobileTabs
						tabConfigs={[
							{
								key: 'dashboard',
								icon: <img src={NimblyLearnDashboardIcon} alt="dashboard-icon" />,
								title: t('nav.dashboard'),
								isActive: tab === 'dashboard',
								onClick: () => {
									this.handleChangeSelection('dashboard');
								},
								isVisible: !!lmsRoleAccess?.dashboard?.view && lmsRole !== 'learner',
							},
							{
								key: 'userManagement',
								icon: <img src={UserManagementIcon} alt="user-management-icon" />,
								title: t('nav.userManagement'),
								isActive: tab === 'users' || tab === 'user-groups',
								subTabs: [
									{
										key: 'users',
										icon: <img src={UsersIcon} alt="users-icon" />,
										title: t('nav.users'),
										isActive: false,
										isVisible: !!lmsRoleAccess?.users?.view,
										onClick: () => {
											this.handleChangeSelection('users');
										},
									},
									{
										key: 'userGroups',
										icon: <img src={UserGroupsIcon} alt="user-groups-icon" />,
										title: t('nav.userGroups'),
										isVisible: !!lmsRoleAccess?.['user-groups']?.view,
										isActive: false,
										onClick: () => {
											this.handleChangeSelection('user-groups');
										},
									},
								],
							},
							{
								key: 'courseManagement',
								icon: <img src={CoursesManagementIcon} alt="course-management-icon" />,
								title: t('nav.courseManagement'),
								isActive: tab === 'courses-and-syllabus',
								onClick: () => {},
								subTabs: [
									{
										key: 'manageCourses',
										icon: <img src={CoursesManagementIcon} alt="course-management-icon" />,
										title: t('nav.manageCourses'),
										isVisible: !!lmsRoleAccess?.['courses-syllabus-manage']?.view,
										isActive: false,
										onClick: () => {
											this.handleChangeSelection('courses-and-syllabus/manage');
										},
									},
									{
										key: 'courses',
										icon: <img src={CoursesIcon} alt="courses-icon" />,
										title: t('nav.courses'),
										isActive: false,
										isVisible: !!lmsRoleAccess?.['courses-syllabus']?.view && lmsRole !== 'learner',
										onClick: () => {
											this.handleChangeSelection('courses-and-syllabus');
										},
									},
								],
							},
							{
								key: 'quizManagement',
								icon: <img src={QuizIcon} alt="quiz-management-icon" />,
								title: t('nav.quizManagement'),
								isActive: tab === 'quiz',
								onClick: () => {
									this.handleChangeSelection('quiz/manage');
								},
								isVisible: !!lmsRoleAccess?.['quiz-manage']?.view && this.props.featureAccess.LMS_QUIZ,
							},
						]}
					/>
				)}
			</Translation>
		);
	};

	_renderMobileDashboardSidebar = (tab) => {
		const { isReady } = this.state;

		return (
			<Translation>
				{(t) => (
					<Tabs>
						<MobileTab
							isActive={isReady && tab === 'overview'}
							onClick={() => this.handleChangeSelection('executive', Common.enums.RoleResources.DASHBOARD_OVERVIEW_ALL)}
						>
							<img src={OverviewIcon} alt="overview-icon" />
							<span>{t('nav.overview')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'overview'}
							onClick={() =>
								this.handleChangeSelection('operational', Common.enums.RoleResources.DASHBOARD_OVERVIEW_ALL)
							}
						>
							<MdOutlineInsertChart className="aa-p-1" size={24} />
							<span className="aa-ml-3">{t('nav.operationalDashboard')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'trends'}
							onClick={() => this.handleChangeSelection('trends', Common.enums.RoleResources.DASHBOARD_TRENDS_ALL)}
						>
							<img src={TrendsIcon} alt="trends-icon" />
							<span>{t('nav.trends')}</span>
						</MobileTab>

						<MobileTab
							isActive={isReady && tab === 'inventory'}
							onClick={() =>
								this.handleChangeSelection('inventory', Common.enums.RoleResources.DASHBOARD_INVENTORY_ALL)
							}
						>
							<img src={InventoryIcon} alt="inventory-icon" />
							<span>{t('nav.inventory')}</span>
						</MobileTab>
						<MobileTab
							isActive={isReady && tab === 'calculation'}
							onClick={() =>
								this.handleChangeSelection('calculation', Common.enums.RoleResources.DASHBOARD_SALESCALCULATIONS_ALL)
							}
						>
							<img src={salesCalculationIcon} alt="sales-calculator-icon" />
							<span>{t('nav.salesCalculation')}</span>
						</MobileTab>
					</Tabs>
				)}
			</Translation>
		);
	};

	_renderMobileSuperadminSidebar = (tab) => {
		const { isReady } = this.state;

		return (
			<Tabs>
				<Tab
					isActive={isReady && tab === 'departmentsgroup'}
					onClick={() => this.handleChangeSelection('departmentsgroup')}
				>
					<img src={OverviewIcon} alt="departments-icon" />
					<span>Organization</span>
				</Tab>
				<Tab isActive={isReady && tab === 'departments'} onClick={() => this.handleChangeSelection('departments')}>
					<img src={OverviewIcon} alt="departments-icon" />
					<span>Organization</span>
				</Tab>
				<Tab isActive={isReady && tab === 'errorlog'} onClick={() => this.handleChangeSelection('errorlog')}>
					<img src={OverviewIcon} alt="departments-icon" />
					<span>Organization</span>
				</Tab>
			</Tabs>
		);
	};

	_renderMobileEmptySidebar = () => {
		return (
			<Tabs>
				<Tab />
			</Tabs>
		);
	};

	_renderIssueFilterSidebar = () => {
		return (
			<SuspenseInitialRender delay={5000} fallback={<LoadingSpinner />}>
				<IssueFilterMobile />
			</SuspenseInitialRender>
		);
	};

	_renderSelections = () => {
		const { location } = this.props;
		const currentScreen = location.pathname;

		let selectedScreen = this.props.screen.dashboard;
		if (currentScreen.includes('superadmin')) selectedScreen = this.props.screen.superadmin;
		else if (currentScreen.includes('admin')) selectedScreen = this.props.screen.admin;

		const { viewTab } = selectedScreen;
		if (currentScreen.includes('superadmin')) return this._renderSuperadminSidebar(viewTab);
		else if (currentScreen.includes('admin')) return this._renderAdminSidebar(viewTab);
		else if (currentScreen.includes('issues') && !currentScreen.includes('analytics')) {
			return this._renderIssueFilterSidebar();
		} else if (currentScreen.includes('settings')) return this._renderSettingSidebar(currentScreen.split('/')[2] || '');
		else if (currentScreen.includes('help')) return this._renderEmptySidebar();

		return this._renderDashboardSidebar(viewTab);
	};

	_renderMobileSelections = () => {
		const { location } = this.props;
		const currentScreen = location.pathname;

		let selectedScreen = this.props.screen.dashboard;
		if (currentScreen.includes('superadmin')) selectedScreen = this.props.screen.superadmin;
		else if (currentScreen.includes('admin')) selectedScreen = this.props.screen.admin;

		const { viewTab } = selectedScreen;

		if (this.props.isMobileFilterOpen) return this._renderIssueFilterSidebar();

		if (currentScreen.includes('superadmin')) {
			return this._renderMobileSuperadminSidebar(viewTab);
		} else if (currentScreen.includes('admin')) {
			return this._renderMobileAdminSidebar(viewTab);
		} else if (currentScreen.includes('nimblylearn')) {
			return this._renderMobileNimblyLearnSidebar(currentScreen.split('/')[2] || '');
		} else if (currentScreen.includes('settings')) {
			return this._renderMobileSettings(currentScreen.split('/')[2] || '');
		}
		return this._renderMobileDashboardSidebar(viewTab);
	};

	render() {
		const { isMobileCollapsed, lmsRoleAccess, adminAccess, lmsRole } = this.props;

		const isMobile = window.matchMedia('(max-width: 991px)').matches;

		return (
			<Translation>
				{(t) => (
					<>
						<SidebarDesktop className="Sidebar-root">
							<NimblyLogo>
								<Link to="/">
									<img src={DesktopLogo} alt="Nimbly Logo" draggable="false" />
								</Link>
							</NimblyLogo>
							{this._renderSelections()}
						</SidebarDesktop>

						{isMobile ? (
							<Overlay isActive={!isMobileCollapsed || this.props.isMobileFilterOpen} onClick={this.handleCloseSidebar}>
								<SidebarMobile
									collapsed={isMobileCollapsed && !this.props.isMobileFilterOpen}
									onClick={(e) => e.stopPropagation()}
								>
									<NimblyMobileLogo>
										<Link to="/">
											<img src={MobileLogo} alt="Nimbly Logo" draggable="false" />
										</Link>
										<span onClick={this.handleCloseSidebar}>&times;</span>
									</NimblyMobileLogo>
									<div className="menu-container">
										<div>
											{this._renderMobileSelections()}
											{!this.props.isMobileFilterOpen && lmsRole !== 'learner' && (
												<GalleryAndFileRepositoryLinks>
													<MobileTab onClick={() => this.handleChangeSelection('gallery', '')}>
														<IoImagesOutline size={25} color="#000" style={{ marginRight: '11px', padding: '5px' }} />
														<span>{t('nav.gallery')}</span>
													</MobileTab>
													<MobileTab onClick={() => this.handleChangeSelection('repository', '')}>
														<FaRegFolderOpen size={25} color="#000" style={{ marginRight: '11px', padding: '5px' }} />
														<span>{t('nav.repository')}</span>
													</MobileTab>
												</GalleryAndFileRepositoryLinks>
											)}
										</div>

										<MobileTabLanguage>
											<LanguageContainer>
												<div>
													<span onClick={() => this.handleLanguageChange('en')}>EN</span>
												</div>
												<div>
													<span onClick={() => this.handleLanguageChange('id')}>ID</span>
												</div>
												<div>
													<span onClick={() => this.handleLanguageChange('cmn')}>CMN</span>
												</div>
											</LanguageContainer>

											<MobileTab isActive={false} onClick={this.handleLogout}>
												<Logout>
													<img src={LogoutIcon} alt="logout-icon" />
												</Logout>
												<span>{t('button.logout')}</span>
											</MobileTab>
										</MobileTabLanguage>
									</div>
								</SidebarMobile>
							</Overlay>
						) : null}
					</>
				)}
			</Translation>
		);
	}
}

const Container = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px 0px;

	@media (min-width: 992px) {
		display: none;
	}
`;

const Logout = styled.div`
	img {
		transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		-o-transform: rotate(-90deg);
	}

	display: flex;
	align-items: center;
`;

const LanguageContainer = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	padding: 16px 0px;
	border-top: 1px solid #c4c4c4;
	border-bottom: 1px solid #c4c4c4;
	margin-bottom: 10px;

	div {
		color: #11998e;
		font-size: 13px;
		line-height: 19px;
	}
`;
const FeatureIcon = styled.img`
	width: 15%;
	height: 15%;
`;

const NimblyLogo = styled.div`
	display: none;
	box-sizing: border-box;
	position: relative;

	width: 200px;
	height: 150px;
	padding: 1.875rem;

	background: #fff;
	text-align: center;

	img {
		width: auto;
		height: 100%;
		object-fit: cover;
	}

	@media (min-width: 992px) {
		display: block;
	}
`;

const NimblyMobileLogo = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid #c4c4c4;

	a {
		width: 80%;

		img {
			width: 100%;
		}
	}

	span {
		display: inline-block;
		width: 20%;
		color: #535353;
		font-size: 1.25rem;
		text-align: right;
	}
`;

const SidebarDesktop = styled.div`
	display: none;
`;

const SidebarMobile = styled.div`
	position: fixed;
	display: flex;
	min-width: 85%;
	top: 0;
	right: 0;
	transform: translate(${(props) => (props.collapsed ? '100%' : '0%')}, 0);
	display: flex;
	flex-direction: column;
	z-index: 2100;
	overflow-y: scroll;

	padding: 1.875rem;

	transition: 50ms all ease-in;

	height: 100%;
	background: #fff;

	.menu-container {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		height: 100%;
	}

	.logout-container {
		padding-top: 1rem;
		border-top: 1px solid #c4c4c4;
	}

	@media (min-width: 992px) {
		display: none;
	}
`;

const Tabs = styled.div`
	width: 100%;
`;

const Tab = styled.div`
	position: relative;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;

	padding-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;

	background: ${(props) => (props.isActive ? '#E6FAED' : 'transparent')};

	transition: 150ms all ease-in-out;

	cursor: pointer;

	img {
		margin-right: 11px;
		filter: ${(props) => (props.isActive ? 'none' : 'grayscale(100%)')};
	}

	i {
		margin-left: 6px;
		margin-right: 13px;
	}

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 22px;

		color: #11998e;
	}

	:hover {
		background: #efeeed;

		img {
			filter: none;
		}
	}
`;

const CollapsibleTab = styled(Tab)`
	background: ${(props) => (props.isActive ? '#efeeed' : 'transparent')};

	.dropdown {
		position: absolute;
		right: 10px;

		img {
			transition: 100ms all ease-in-out;
			transform: rotate(${(props) => (props.isCollapsed ? '270deg' : '90deg')});
		}
	}
`;

const MobileCollapsibleTab = styled(Tab)`
	background: ${(props) => (props.isCollapsed ? 'transparent' : '#E6FAED')};
	margin-left: -30px;
	width: calc(100% + 60px);
	padding-left: 30px;
	padding-right: 30px;

	.dropdown {
		position: absolute;
		right: 20px;

		img {
			transition: 100ms all ease-in-out;
			transform: rotate(${(props) => (props.isCollapsed ? '270deg' : '90deg')});
		}
	}
`;

const MobileGroupedTabs = styled.div`
	position: relative;
	padding: 8px 2px;
	display: ${(props) => (props.isCollapsed ? 'none' : 'block')};
	transition: 100ms all ease-in-out;
`;

const GroupedTabs = styled.div`
	position: relative;
	padding: 10px 0;
	display: ${(props) => (props.isCollapsed ? 'none' : 'block')};
	transition: 100ms all ease-in-out;
	background: #fafafa;
`;

const SubTab = styled.div`
	min-height: 30px;
	padding-left: 25px;

	display: flex;
	flex-direction: row;
	align-items: center;

	background: ${(props) => (props.isActive ? '#efeeed' : 'transparent')};
	transition: 150ms all ease-in-out;
	cursor: pointer;

	img {
		margin-right: 11px;
		filter: ${(props) => (props.isActive ? 'none' : 'grayscale(100%)')};
	}

	span {
		font-size: 12px;
		line-height: 18px;
		color: #11998e;
	}

	:hover {
		background: #efeeed;
	}
`;

const MobileSubTab = styled(Tab)`
	display: flex;
	flex-direction: row;
	align-items: center;

	transition: 150ms all ease-in-out;
	cursor: pointer;
	background-color: transparent;

	img {
		margin-right: 11px;
		filter: ${(props) => (props.isActive ? 'none' : 'grayscale(100%)')};
	}

	span {
		font-weight: 400;
	}

	:hover {
		background-color: transparent;
	}
`;

const MobileTab = styled(Tab)`
	padding: 6px 0;
	background: transparent;

	@media (min-height: 660px) {
		padding: 10px 0;
	}
`;

const MobileTabLanguage = styled(MobileTab)`
	flex-direction: column;
	margin-top: 30px;
	width: 100%;

	:hover {
		background: transparent !important;
	}
`;

const OverlayBase = styled.div`
	position: fixed;
	display: ${(props) => (props.isActive ? 'block' : 'none')};
	opacity: 1;

	transition: 150ms all ease-in-out;

	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.8);
	z-index: ${(props) => (props.isActive ? '99999' : '0')};

	@media (min-width: 992px) {
		display: none;
	}
`;

const GalleryAndFileRepositoryLinks = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 16px 0px;
	border-top: 1px solid #c4c4c4;
`;

const Overlay = (props) => {
	const isDesktop = useMediaQuery('(min-width: 992px)');

	if (isDesktop) return null;

	return <OverlayBase {...props} />;
};

const connectedSidebar = connect((state, props) => {
	const features = state.featureAccess.features;
	const isQuizEnabled = features.LMS_QUIZ;
	const adminAccess = state.userAccess.admin.sites.all.permissions.edit || !isQuizEnabled;
	const isMobileFilterOpen = state.issues.isMobileFilterOpen;
	return {
		api: state.api,
		analytics: state.analytics,
		profile: state.firebase.profile,
		location: state.router.location,
		screen: state.screen,
		auth: state.firebase.auth,
		lmsRoleAccess: state.userAccess.lmsRoleAccess,
		featureAccess: state.featureAccess.features,
		adminAccess,
		isQuizEnabled,
		lmsRole: state.userAccess.lmsRole,
		isMobileFilterOpen: isMobileFilterOpen,
	};
})(Sidebar);

const wrappedSidebar = firebaseConnect()(connectedSidebar);

export default withRouter(wrappedSidebar);
