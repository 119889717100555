import { action, createAsyncAction } from 'typesafe-actions';
import { Moment } from 'moment';
import {
	CreateIssueRequest,
	ExtendedIssue,
	IssueStatus,
	IssueTrackerFilter,
	Priority,
	Severity,
} from '@nimbly-technologies/nimbly-common';

import * as types from './issues.actionTypes';
import { IssueFilters, IssueWithIndex } from './issues.reducer';

/**
 * ...
 * @param value
 *
 */
export const setIssuesList = (data: Array<ExtendedIssue>) => action(types.FETCH_ISSUES_LIST, { data });

/**
 * ...
 *
 */
export const setIsSingleLoading = (value: boolean[]) => action(types.SET_IS_SINGLE_LOADING, { value });

/**
 * ...
 *
 */
export const setIsLoading = () => action(types.SET_IS_LOADING, {});

/**
 * ...
 *
 */
export const setStopLoading = () => action(types.SET_STOP_LOADING, {});

/**
 * ...
 * @param photos
 *
 */
export const showImageModal = (photos: any) => action(types.SHOW_MODAL, { photos });

/**
 * ...
 * @param status
 *
 */
export const showCreateIssueModal = (status: any) => action(types.SHOW_CREATE_ISSUE_MODAL, { status });

/**
 * ...
 * @param status
 *
 */
export const showModalMember = (status: any) => action(types.SHOW_MODAL_MEMBER, { status });

/**
 * ...
 *
 */
export const dismissImageModal = () => action(types.DISMISS_MODAL, {});

/**
 * Mark selectedIssue as read
 *
 */
export const readIssue = () => action(types.READ_ISSUE, {});

/**
 * Mark selectedIssues as read
 * @param type
 *
 */
export const readOrUnreadIssues = (type: 'read' | 'unread') => action(types.READ_OR_UNREAD_ISSUES, { type });

/**
 * Select issue to be viewed
 * @param issue
 *
 */
export const selectIssue = (issue: IssueWithIndex | null) => action(types.SELECT_ISSUE, { issue });

/**
 * Select issues in bulk for editing
 * @param issues
 *
 */
export const selectIssues = (issues: IssueWithIndex[]) => action(types.SELECT_ISSUES, { issues });

/**
 * Option that is available to update data on selectedIssue. Update issue read status to read.
 *
 */
export const singleIssueOptionReadIssue = () => action(types.READ_ISSUE, {});

/**
 * Option that is available to update data on selectedIssue. Update issue severity.
 * @param severity red / yellow
 *
 */
export const singleIssueOptionSetIssueSeverity = (severity: Severity) =>
	action(types.SET_SEVERITY, { severity: severity });

/**
 * Option that is available to update data on selectedIssue. Update issue status.
 * @param status resolved / open
 *
 */
export const singleIssueOptionSetIssueStatus = (status: IssueStatus) => action(types.SET_STATUS, { status: status });

/**
 * Option that is available to update data on selectedIssue. Update issue priorityV2.
 * @param priorityKey p0..p2
 *
 */
export const singleIssueOptionSetIssuePriorityV2 = (priorityKey: string) =>
	action(types.SET_PRIORITY_V2, { priorityKey });

/**
 * Option that is available to update data on selectedIssue. Update issue assignee.
 * @param user user id in string
 *
 */
export const singleIssueOptionSetIssueAssignee = (userID: string, name: string) =>
	action(types.SET_ASSIGNEE, { userID, name });

/**
 * Option that is available to update data on selectedIssue. Update issue department
 * @param department department id in string
 *
 */
export const singleIssueOptionSetIssueDepartment = (departments: string) =>
	action(types.SET_DEPARTMENT, { departments });

export const singleIssueOptionSetIssueReporterDepartment = (department: string) =>
	action(types.SET_REPORTER_DEPARTMENT, { department });

/**
 * Option that is available to update data on selectedIssue. Update issue due date.
 * @param date standarized javascript date format
 *
 */
export const singleIssueOptionSetIssueDueDate = (date: Date) => action(types.SET_DUE_DATE, { date: date });

/**
 * Option that is available to update data on selectedIssue. Update issue priority
 * @param priority normal / high
 *
 */
export const singleIssueOptionSetIssuePriority = (priority: Priority) =>
	action(types.SET_PRIORITY, { priority: priority });

/**
 * Option that is available to update data on selectedIssue. Update issue member list
 * @param priority normal / high
 *
 */
export const singleIssueOptionSetIssueMembers = (newMembers: { [uid: string]: string }) =>
	action(types.SET_MEMBERS, { newMembers });

export const singleIssueOptionSetIssueMemberDetails = (memberDetails: {
	[key: string]: {
		name: string;
		email: string;
		status: string;
	};
}) => action(types.SET_MEMBER_DETAILS, { memberDetails });

/**
 * Filter issues based on question text
 * @param text question text in string
 *
 */
export const setIssuesFilteredQuestion = (text: any | null) => action(types.SET_FILTERED_QUESTION, { text: text });

/**
 *
 * @param startDate
 * @param endDate
 */
export const setIssuesFilteredDate = (startDate: Moment | null, endDate: Moment | null) =>
	action(types.SET_FILTERED_DATE, { startDate, endDate });

/**
 *
 * @param startDate
 * @param endDate
 */
export const setIssuesTempFilteredDate = (startDate: Moment | null, endDate: Moment | null) =>
	action(types.SET_TEMP_FILTERED_DATE, { startDate, endDate });

/**
 *
 * @param startDate
 */
export const setIssuesFilteredStartDate = (startDate: Moment | null) =>
	action(types.SET_FILTERED_START_DATE, { startDate });

/**
 *
 * @param endDate
 */
export const setIssuesFilteredEndDate = (endDate: Moment | null) => action(types.SET_FILTERED_END_DATE, { endDate });

/**
 * Clear filter options
 *
 */
export const clearIssuesFilters = () => action(types.CLEAR_ISSUES_FILTERS, {});

export const setIssuesFilters = (filters: Partial<IssueFilters>) => action(types.SET_ISSUES_FILTERS, { filters });

export const setIssuesTempFilters = (tempFilters: Partial<IssueFilters>) =>
	action(types.SET_ISSUES_TEMP_FILTERS, { tempFilters });

/**
 * Set sorting option for the issues list
 * @param sortBy
 * @param sortDirection
 *
 */
export const setIssuesSort = (sortBy: string, sortDirection: 'asc' | 'desc') =>
	action(types.SET_SORT, { sortBy, sortDirection });

/**
 * ...
 * @param query summarize query as string
 *
 */
export const setFilterQuery = (query: string) => action(types.SET_FILTER_QUERY, { query });

/**
 * ...
 *
 */
export const scrollIssuesList = () => action(types.SCROLL_LIST);

/**
 *
 * @param processedIssueKeys
 *
 */
export const setProcessedIssues = (processedIssueKeys: any) =>
	action(types.SET_PROCESSED_ISSUE, { processedIssueKeys });

export const setIsFilterOpen = (isFilterOpen: boolean) => action(types.SET_IS_FILTER_OPEN, { isFilterOpen });

export const setIsNewCustomFilterModalOpen = (isNewCustomFilterModalOpen: boolean) =>
	action(types.SET_IS_NEW_CUSTOM_FILTER_MODAL_OPEN, { isNewCustomFilterModalOpen });

export const setSelectedCustomFilter = (filter: { key: string; name: string } | null) =>
	action(types.SET_SELECTED_CUSTOM_FILTER, filter);

export const setCustomFilters = (customFilters: IssueTrackerFilter[]) =>
	action(types.SET_CUSTOM_FILTERS, { customFilters });

export const resetSelectedCustomFilter = () => action(types.RESET_SELECTED_CUSTOM_FILTER);

export const setActivityTab = (tab: string) => action(types.SET_ACTIVITY_TAB, { tab });

export const setActivityOrder = (order: string) => action(types.SET_ACTIVITY_ORDER, { order });

export const setPage = (page: number) => action(types.SET_PAGE, { page });

export const setMobileFilterOpen = (mobileFilterOpen: boolean) =>
	action(types.SET_MOBILE_FILTER_OPEN, { mobileFilterOpen });

// SAGA ACTIONS

export const quickResolveIssueAsync = createAsyncAction(
	types.QUICK_RESOLVE_ISSUE_REQUEST,
	types.QUICK_RESOLVE_ISSUE_SUCCESS,
	types.QUICK_RESOLVE_ISSUE_FAILURE,
)<{ issueID: string }, { issue: IssueWithIndex }, Error>();

export const updateIssuesSingleAsync = createAsyncAction(
	types.UPDATE_ISSUE_SINGLE_REQUEST,
	types.UPDATE_ISSUE_SINGLE_SUCCESS,
	types.UPDATE_ISSUE_SINGLE_FAILURE,
)<
	{ onSuccess?: () => void },
	{
		issue: any;
		issues: ExtendedIssue[];
	},
	Error
>();

export const fetchIssueBatchAsync = createAsyncAction(
	types.FETCH_ISSUE_BATCH_REQUEST,
	types.FETCH_ISSUE_BATCH_SUCCESS,
	types.FETCH_ISSUE_BATCH_FAILURE,
)<
	{ selectedIssueKey?: string | null; loadMore?: boolean },
	{ issues: ExtendedIssue[]; selectedIssue?: IssueWithIndex | null; isPageEnd: boolean },
	Error
>();

export const fetchIssueCustomFiltersAsync = createAsyncAction(
	types.FETCH_ISSUE_CUSTOM_FILTERS_REQUEST,
	types.FETCH_ISSUE_CUSTOM_FILTERS_SUCCESS,
	types.FETCH_ISSUE_CUSTOM_FILTERS_FAILURE,
)<{ shouldApplyDefault: boolean }, { customFilters: IssueTrackerFilter[] }, Error>();

export const uploadIssuePhotoAsync = createAsyncAction(
	types.UPLOAD_ISSUE_PHOTO_REQUEST,
	types.UPLOAD_ISSUE_PHOTO_SUCCESS,
	types.UPLOAD_ISSUE_PHOTO_FAILURE,
)<undefined, any, Error>();

export const postIssueCommentAsync = createAsyncAction(
	types.POST_ISSUE_COMMENT_REQUEST,
	types.POST_ISSUE_COMMENT_SUCCESS,
	types.POST_ISSUE_COMMENT_FAILURE,
)<undefined, any, Error>();

export const createIssueAsync = createAsyncAction(
	types.CREATE_ISSUE_REQUEST,
	types.CREATE_ISSUE_SUCCESS,
	types.CREATE_ISSUE_FAILURE,
)<
	{
		issue: CreateIssueRequest;
	},
	any,
	Error
>();

export const createIssueV2Async = createAsyncAction(
	types.CREATE_ISSUE_V2_REQUEST,
	types.CREATE_ISSUE_V2_SUCCESS,
	types.CREATE_ISSUE_V2_FAILURE,
)<
	{
		issue: CreateIssueRequest;
	},
	{ issues: ExtendedIssue[]; selectedIssue: IssueWithIndex | null },
	Error
>();

export const notifyIssueMemberAsync = createAsyncAction(
	types.NOTIFY_ISSUE_MEMBER_REQUEST,
	types.NOTIFY_ISSUE_MEMBER_SUCCESS,
	types.NOTIFY_ISSUE_MEMBER_FAILURE,
)<undefined, any, Error>();

export const fetchIssueApprovalDataAsync = createAsyncAction(
	types.GET_APPROVAL_DETAILS_REQUEST,
	types.GET_APPROVAL_DETAILS_SUCCESS,
	types.GET_APPROVAL_DETAILS_FAILURE,
)<{ issueID: string }, any, any>();

export const fetchApproversListAync = createAsyncAction(
	types.FETCH_APPROVER_LIST_REQUEST,
	types.FETCH_APPROVER_LIST_SUCCESS,
	types.FETCH_APPROVER_LIST_FAILURE,
)<{ requesterID: string }, any, any>();
