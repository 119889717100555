import React, { useState } from 'react';
import { RootState } from 'store/rootReducers';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components/macro';

import Header from '../Header/Header';
// @ts-ignore
import ScrollbarSize from 'react-scrollbar-size';
import { setScrollbarWidth } from '../../../reducers/styling';
import SidebarMobile from '../Sidebar/Sidebar_MOBILE';
import Sidebar from '../Sidebar/Sidebar_DESKTOP';
import FeatureAccessModal from '../../../components/FeatureAccessModal/FeatureAccessModal';
import ProgressBoard from '../../../components/tutorial/ProgressBoard/ProgressBoard';
import { getStringValue } from 'helpers/commons';
import { setMobileFilterOpen } from 'reducers/issues/issues.action';

export interface LayoutProps {
	header?: boolean;
	footer?: boolean;
	children?: React.ReactNode;
	containerStyle?: string;
	desktopSidebarStyle?: string;
}

interface ContainerProps {
	customStyle?: string;
}

export const Layout = (props: LayoutProps) => {
	const dispatch = useDispatch();
	const match = useRouteMatch();

	const { currentProgress, cumulativeProgress } = useSelector((state: RootState) => state.tutorial);
	const isTutorial = useSelector(
		(state: RootState) =>
			state.tutorial.isOrganizationOnboarding &&
			!state.tutorial.cumulativeProgress['WELCOME'] &&
			!state.tutorial.cumulativeProgress['DEPARTMENTS'] &&
			!state.tutorial.cumulativeProgress['USER_ROLES'] &&
			!state.tutorial.cumulativeProgress['ADD_USERS'] &&
			!state.tutorial.cumulativeProgress['SETUP_QUESTIONNAIRE'] &&
			!state.tutorial.cumulativeProgress['ADD_A_SITE'] &&
			!state.tutorial.cumulativeProgress['SCHEDULE_REPORT'],
	);

	const [isMobileCollapsed, setIsMobileCollapsed] = useState(true);

	const render = () => {
		return (
			<Root>
				{isTutorial ? (
					<ProgressBoard tutorialCurrentProgress={currentProgress} tutorialCumulative={cumulativeProgress} />
				) : null}

				<Container customStyle={props?.containerStyle}>
					{/* @ts-ignore */}
					<Header {...props} onShowSidebar={handleShowSidebar} />
					<FeatureAccessModal />
					{/* TODO: find better way to prevent extra render when it's in mobile */}
					<div className="default-small:aa-hidden">{_renderSidebar()}</div>
					<div className="aa-hidden default-small:aa-block">
						<Sidebar customStyle={props?.desktopSidebarStyle} />
					</div>
					{props.children}
				</Container>
				<ScrollbarSize onLoad={handleSaveScrollbarWidth} onChange={handleSaveScrollbarWidth} />
			</Root>
		);
	};

	const handleSaveScrollbarWidth = (measurements: any) => {
		dispatch(setScrollbarWidth(measurements.scrollbarWidth));
	};

	const handleShowSidebar = () => {
		setIsMobileCollapsed(false);
	};

	const handleCloseSidebar = () => {
		setIsMobileCollapsed(true);
		dispatch(setMobileFilterOpen(false));
	};

	const _renderSidebar = () => {
		if (match?.path.includes('/superadmin/organizations/:organizationKey')) {
			return null;
		}

		// @ts-ignore
		return <SidebarMobile {...props} isMobileCollapsed={isMobileCollapsed} onDismiss={handleCloseSidebar} />;
	};

	return render();
};

const Root = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: #efeeed;
`;

const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: row;
	flex: 1;
	${(props) => getStringValue(props?.customStyle)};
`;

export default Layout;
