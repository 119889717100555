/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { firebaseConnect } from 'react-redux-firebase';
import styled from 'styled-components/macro';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Activity, enums } from 'nimbly-common';

import Navigation from '../Navigation/Navigation';

import * as siteDetailsAction from 'reducers/site/siteDetails/siteDetails.action';
import * as siteScheduleAction from 'reducers/site/siteSchedule/siteSchedule.action';

import MobileLogo from '../../../assets/icon/header-mobile-nimbly-white.svg';
import FilterIcon from '../../../assets/icon/header-mobile-filter.svg';
import HamburgerIcon from '../../../assets/icon/header-mobile-hamburger.svg';
import ChevronLightIcon from '../../../assets/icon/chevron-light.svg';

import * as featureAccessAction from 'reducers/featureAccess/featureAccess.action';
import i18n from '../../../i18n';
import 'moment/locale/pt';
import 'moment/locale/id';
import 'moment/locale/es';
import { createNewActivity } from 'constants/api/v1';
import { withRouter } from 'react-router';

class MobileHeaderBar extends React.Component {
	state = {
		allowLogout: true,
		showLanguageOptions: false,
		_isBusy: false,
	};

	componentDidMount(prev) {
		this.handleLanguageChange(this.props.profile.language, false);
		window.addEventListener('click', this.handleCloseOptions, false);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.handleCloseOptions, false);
	}

	handleCloseOptions = () => {
		this.setState({ showLanguageOptions: false });
	};

	handleShowLanguageOptions = (e) => {
		e.stopPropagation();
		if (this.props.featureAccessLanguage) {
			this.handleCloseOptions();
			if (!this.state.showLanguageOptions) {
				setTimeout(() => {
					this.setState({ showLanguageOptions: !this.state.showLanguageOptions });
				}, 0);
			}
		} else {
			this.props.dispatch(featureAccessAction.showFeatureModal());
		}
	};

	handleLanguageChange = (lang, recordAnalytics) => {
		// TODO: change language directly firebase DB
		const { auth } = this.props;
		const userRef = this.props.firebase.database().ref(`user/${auth.uid}`);
		this.props.firebase.update(userRef, {
			language: lang,
		});
		this.setState({ language: lang });
		i18n.changeLanguage(lang);
		moment.locale(lang);

		if (recordAnalytics === true)
			ReactGA.event({
				category: 'Navigation',
				action: 'Language - Changed to: ' + lang,
			});
	};

	handleLogout = () => {
		const { auth } = this.props;
		localStorage.removeItem('enrollmentStatus');

		let hasChangesSiteDetails = this.props.hasChangesSiteDetails;
		let hasChangesSiteSchedule = this.props.hasChangesSiteSchedule;

		if (window.fcWidget) {
			window.fcWidget.user.clear();
		}

		if (hasChangesSiteDetails || hasChangesSiteSchedule) {
			if (hasChangesSiteDetails) {
				this.props.dispatch(siteDetailsAction.setHasChanges(false));
			}

			if (hasChangesSiteSchedule) {
				this.props.dispatch(siteScheduleAction.setHasChanges(false));
			}

			// eslint-disable-next-line no-restricted-globals
			if (!confirm('You have made changes. Leaving this page will not save those changes.')) {
				if (hasChangesSiteDetails) {
					this.props.dispatch(siteDetailsAction.setHasChanges(true));
				}

				if (hasChangesSiteSchedule) {
					this.props.dispatch(siteScheduleAction.setHasChanges(true));
				}

				return;
			}
		}

		// Create User Logout Activity
		const activity = new Activity({
			entity: enums.Entity.USER,
			entityID: auth.uid,
			permission: enums.Permission.View,
			type: enums.ActivityType.LOGOUT_USER,
			message: `User Logout: ${auth.displayName}`,
			timestamp: new Date(),
			snapshot: {
				userData: {
					email: auth.email,
					userID: auth.uid,
					displayName: auth.displayName,
				},
				lastSignInTime: auth.lastLoginAt,
				platform: 'admin',
			},
		});

		// Push to placeholder loading page while we log out
		// Timeout is put so the state change in hasChanges have time to update first.
		// If not, double dialog will appear
		window.onbeforeunload = null;
		setTimeout(() => {
			this.props.dispatch(push('/loading'));
			createNewActivity(activity).then(() => {
				this.props.firebase.logout().then(() => {
					// Reload the page to empty the redux state and start from 0
					window.location.reload();
				});
			});
		}, 250);
	};

	handleClickBurgerMenu = () => {
		this.props.onShowSidebar();
	};

	handleClickFilterMenu = () => {};

	_hiddenHeader = () => {
		const { match } = this.props;
		if (match.path.includes('/purchaseorder/:poKey')) {
			return true;
		}
		return false;
	};

	render() {
		const { location, t, lmsRole, profile } = this.props;
		return (
			<Root>
				{lmsRole === 'learner' && profile?.role === 'auditor' ? null : (
					<NavigationBar>
						<Navigation />

						<NavigationSection>
							<DisplayName>{this.props.profile?.displayName}</DisplayName>
							<div className="language" onClick={this.handleShowLanguageOptions}>
								{t('locale').toUpperCase()}
								<span>
									<img src={ChevronLightIcon} alt="language-select" />
								</span>
								<Select isVisible={this.state.showLanguageOptions}>
									{/* TODO change list dinamically */}
									<ul>
										<li onClick={() => this.handleLanguageChange('en', true)}>EN</li>
										<li onClick={() => this.handleLanguageChange('id', true)}>ID</li>
										<li onClick={() => this.handleLanguageChange('pt', true)}>PT</li>
									</ul>
								</Select>
							</div>
							<button onClick={this.handleLogout}>{t('button.logout')}</button>
						</NavigationSection>
					</NavigationBar>
				)}

				<MobileHeader isHidden={this._hiddenHeader()}>
					<MobileNavigation>
						<div>
							<img src={MobileLogo} alt="logo" />
							<span>Nimbly Technologies</span>
						</div>
						<div className="right" onClick={this.handleClickBurgerMenu}>
							<img src={HamburgerIcon} alt="menuicon" />
						</div>
					</MobileNavigation>
					<MobileBlurBackground />
				</MobileHeader>
			</Root>
		);
	}
}

const DisplayName = styled.div`
	color: #fff;
	min-width: 300px;
	text-align: right;
`;

const Root = styled.header`
	position: relative;
	width: 100%;
	height: 0;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	z-index: 998;

	@media (min-width: 992px) {
		display: none;
	}
`;

const NavigationBar = styled.div`
	position: fixed;
	bottom: 0;

	width: 100%;
	height: 60px;

	background: #fafafa;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	padding: 0;
`;

const MobileHeader = styled.header`
	display: ${(props) => (props.isHidden ? 'none' : 'block')};
	position: fixed;
	width: 100%;
	height: 54px;

	// @media (min-width: 992px) {
	//   display: none;
	// }
`;

const MobileNavigation = styled.div`
	position: absolute;
	top: 0;

	// allows text to go on top of blur-background
	z-index: 10;
	opacity: 1;

	height: 54px;
	width: 100%;
	padding: 0 1.25rem;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	div {
		display: flex;
		flex-direction: row;
		align-items: baseline;

		span {
			padding-left: 0.25rem;

			color: #fff;
			font-weight: 600;
			font-size: 15px;
			letter-spacing: -0.01em;
		}
	}

	.right {
		display: flex;
		align-items: center;

		span {
			margin-right: 4px;
			font-size: 14px;
			font-weight: 400;
		}

		img {
			margin-bottom: 2px;
		}
	}
`;

const MobileBlurBackground = styled.div`
	position: relative;
	top: 0;
	height: 100%;
	width: 100%;

	// background: linear-gradient(81.03deg, #5049b2 15.57%, #24ed75 100%), #c4c4c4;
	background: #3cd070;
	opacity: 1;

	::before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 5;

		// background: linear-gradient(81.03deg, #5049b2 15.57%, #24ed75 100%), #c4c4c4;
		background: #3cd070;
		filter: blur(6px);
		opacity: 1;
	}
`;

const NavigationSection = styled.div`
	display: none;

	.language {
		position: relative;
		display: inline-flex;
		flex-direction: row;
		justify-content: center;

		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		color: #fff;
		cursor: pointer;

		> span {
			margin-left: 8px;
			margin-top: auto;
			margin-bottom: auto;

			img {
				height: 8px;
				transform: rotate(90deg);
			}
		}
	}

	button {
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 22px;

		color: #fff;
		padding: 0;
	}

	// @media (min-width: 992px) {
	//   display: flex;
	//   flex-direction: row;
	//   justify-content: flex-end;
	//   align-items: baseline;

	//   > *:not(:first-child) {
	//     margin-left: 24px;
	//   }
	// }
`;

const Select = styled.div`
	margin-left: 0;
	margin-top: auto;
	margin-bottom: auto;

	position: absolute;
	top: 24px;
	right: 0;

	display: flex;
	justify-content: flex-start;
	align-items: center;
	visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};

	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	background: #ffffff;

	transition: 100ms all ease-in-out;

	ul {
		display: flex;
		flex-direction: column;
		padding-left: 0;

		height: 100%;

		z-index: 12;
	}

	li {
		width: 100%;
		padding-left: 24px;
		padding-right: 24px;

		font-style: normal;
		font-weight: normal;
		font-size: 0.75rem;
		line-height: 1.1875rem;
		color: #828282;

		height: 30px;

		display: flex;
		align-items: center;
		cursor: pointer;

		transition: 100ms all ease-in-out;
		opacity: ${(props) => (props.isVisible ? '1' : '0')};

		:hover {
			background: #efeeed;
			color: #11998e;
		}
	}
`;

const connectedHeader = connect((state) => ({
	account: state.account,
	auth: state.firebase.auth,
	profile: state.firebase.profile,
	hasChangesSiteDetails: state.site.siteDetails.hasChanges,
	hasChangesSiteSchedule: state.site.siteSchedule.hasChanges,
	featureAccessLanguage: state.featureAccess.features[enums.Features.MULTI_LANGUAGE_SUPPORT],
	lmsRole: state.userAccess.lmsRole,
}))(MobileHeaderBar);

const wrappedHeader = firebaseConnect()(connectedHeader);

export default withRouter(withTranslation()(wrappedHeader));
