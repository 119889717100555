import { UpdateIssueRequest, UsecaseReturn, User } from '@nimbly-technologies/nimbly-common';
import { Issue } from 'core/domain/entities/issue/issue';
import React from 'react';

enum IssuePriorityTypeV2 {
	LOW = 'p2',
	MEDIUM = 'p1',
	HIGH = 'p0',
}

type GetIssueByIDResponse = UsecaseReturn<Issue>;

interface IssueStatusOption {
	value: Issue['status'];
	label: string;
	subLabel?: string;
	color: string;
}

interface IssuePriorityOption {
	value: Issue['priorityV2']['key'];
	label: string;
	icon: string;
}

interface IssueFlagOption {
	value: Issue['severity'];
	label: string;
	Icon: React.ReactNode;
}

interface UpdatingIssueStatus {
	[issueID: string]: Issue['status'];
}

type UpdateIssueRequestPayload = Omit<UpdateIssueRequest, 'issueID'> & {
	triggerNotification?: boolean;
};

interface UpdateIssueUseCaseParamsBase {
	issue: Pick<Issue, 'issueID' | 'type'>;
}

interface UpdateIssueStatusUseCaseParams extends UpdateIssueUseCaseParamsBase {
	user: Pick<User, 'userID' | 'displayName'>;
}

type UpdateIssuePriorityUseCaseParams = UpdateIssueUseCaseParamsBase;

interface UpdateIssueReadStatusParams {
	issueIds: string[];
	hasRead: boolean;
}

interface IssueMessageTranslationI {
	comment: string;
	issue_message_id: string;
	target_language: string;
}

interface IssueMessageTranslation {
	translated_text: string;
	message: string;
}

type IssueMessageTranslationResponse = UsecaseReturn<IssueMessageTranslation>;

type UpdateIssueStatusOptions =
	| 'markAllAsRead'
	| 'markAllAsUnread'
	| 'markAsRead'
	| 'markAsUnread'
	| 'toggleReadStatus';

export { IssuePriorityTypeV2 };
export type {
	GetIssueByIDResponse,
	IssueStatusOption,
	IssuePriorityOption,
	IssueFlagOption,
	UpdatingIssueStatus,
	UpdateIssueRequestPayload,
	UpdateIssueUseCaseParamsBase,
	UpdateIssueStatusUseCaseParams,
	UpdateIssuePriorityUseCaseParams,
	UpdateIssueReadStatusParams,
	UpdateIssueStatusOptions,
	IssueMessageTranslationI,
	IssueMessageTranslationResponse,
};
